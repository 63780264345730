<template>
<div class="section-four w-100 pb-5 pt-lg--10">
    <div class="row justify-content-center min-vh-125">
        <div class="col-10">
            <div class="row justify-content-center my-5">
                <div class="col-12">
                    <div class="row justify-content-center">
                    <div class="page-title style1 col-12 mb-3">
                        <h2 class="text-grey-900 fw-700 font-lg mb-0 pb-3 d-block">Terms Condition</h2>
                    </div>
                </div>
                <div v-if="!isLoad" class="row">
                    <div class="col-12">
                        <div class="ck-content" v-html="tnc.terms_condition"></div>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-lg-12">
                        <div class="my-3 text-center">
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center mt-5">
                    <div class="page-title style1 col-12 mb-3">
                        <h2 class="text-grey-900 fw-700 font-lg mb-0 d-block pb-3 mt-3">Privacy Policy</h2>
                    </div>
                </div>
                <div v-if="!isLoad" class="row justify-content-center">
                    <div class="col-12">
                        <div class="ck-content" v-html="tnc.privacy_policy"></div>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-lg-12">
                        <div class="my-3 text-center">
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from 'axios'
export default {
    name: `Browse`,
    data(){
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            tnc: {
                terms_condition: '',
                privacy_policy: ''
            },
        }
    },
    created(){
        this.getTnc()
    },
    methods: {
        async getTnc(){
            await axios.get(`${process.env.VUE_APP_URL_API}/terms-condition-privacy-policy`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.tnc = res.data.data
                }
            }).catch((err) => console.error(err)).finally(() => this.isLoad = false)
        },
    }
};
</script>

<style scoped>
    .how-to-work {
        min-height: 50vh;
    }
.border-bottom-current {
        border-bottom: 5px solid var(--theme-color);
    }
    .border-current {
        border: 0.5px solid var(--theme-color);
    }

    .footer-wrapper form {
        height: 50px;
        position: relative;
        bottom: 0;
    }

    .accordion .card button::after {
        color: #FFFFFF;
    }

    .footer-wrapper form input {
        height: 100%;
    }
    .footer-wrapper form button {
        height: 100%;
    }

    .accordion .card >>> button p{
        color: #FFFFFF !important;
        margin-bottom: 0;
    }
</style>